import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./AuthContext";
import { Base64 } from "js-base64";
import { isExpired, decodeToken } from "react-jwt";

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface UserDataProps {
  idRol: number;
  name: string;
  user: string;
  userId: number;
}

interface ResponseProps extends Response {
  success: boolean;
  token: string;
}

const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [idRole, setIdRole] = useState<null | number>(null);

  useEffect(() => {
    validateSession();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsError(false);
    }, 3000);
  }, [isSuccess]);

  useEffect(() => {
    const tokenCoded = localStorage.getItem("info");
    if (tokenCoded) {
      const dataUser: UserDataProps | null = decodeToken(tokenCoded);
      dataUser && setIdRole(dataUser.idRol);
    }
  }, []);

  const logoutHandler = async () => {
    setIsLoading(true);
    localStorage.clear();
    setIsLoggedIn(false);
    navigate("/");
    setIsLoading(false);
  };

  const loginHandler = async (Username: string, Password: string) => {
    setIsLoading(true);
    try {
      // const loop1Pass = Base64.encode(pass);
      // const loop2Pass = Base64.encode(loop1Pass);
      const data = {
        Username,
        Password,
      };
      let options: RequestInit = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Host: "crystinordermanager.quattroapps.app",
        },
        credentials: "include",
      };
      const res = await fetch(`${process.env.REACT_APP_DEV_API}/auth`, options);
      const response = await res.json();
      if (response.success) {
        localStorage.setItem("info", response.token);
        navigate(0);
        setIsSuccess(true);
      } else {
        setIsLoading(false);
        setIsError(true);
      }
    } catch (e) {
      setIsLoading(false);
      setIsError(true);
      throw new Error("Not Authorized, please contact the Administrator");
    }
  };

  const validateSession = () => {
    if (validateToken()) {
      setIsLoggedIn(true);
      setIsLoading(false);
    } else {
      logoutHandler();
    }
  };

  const validateToken = (): boolean => {
    const token = localStorage.getItem("info");
    if (token) {
      const expired = isExpired(token);
      if (expired) return false;
    } else {
      return false;
    }
    return true;
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isSuccess,
        isError,
        isLoading,
        idRole,
        onLogin: loginHandler,
        onLogout: logoutHandler,
        validateToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
